"use client"
import React, { ReactElement } from "react";

import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import RegisterVideo from "./RegisterVideo";

export function Section1() {
    const words = ["Owners", "Practitioners", "Consultants", "Academicians", "Brands", "Clients"];

    return (
/*         <div className="h-[20rem] flex flex-row justify-center items-center  border  bg-grid-small-black/[0.2]  m-20  rounded-3xl">
 */      <div className="mx-auto flex flex-row overflow-hidden justify-center bg-grid-small-black/[0.2] rounded mt-10 p-10 bg-primary/10 ">

            <div className="lg:basis-3/4 h-[20rem] flex flex-col justify-center items-center px-4  ">

                <div className=" text-xl mx-auto  font-light bg-gradient-to-br text-black  text-center">
                    <p className="mb-2 font-bold">All-in-One Digital Platform for Physiotherapists</p>
                    {words.map((word, index) => (
                        <span key={index} className="text-xl md:text-3xl bg-gradient-to-br" >
                            {word}{index !== words.length - 1 ? ', ' : ' '}&nbsp;
                        </span>
                    ))}
                </div>
                <div className="mt-10">
                    <Link href="/register" title=""
                        className="hidden md:flex rounded-xl bg-[#545b9e] text-white hover:bg-[#42487d] px-3 md:px-10 py-3 font-xs
                        lg:font-medium focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 ">
                        Register Today for Free Digital Profile
                    </Link>
                    <Link href="/register" title=""
                        className="md:hidden rounded-xl bg-[#545b9e] text-white hover:bg-[#42487d] px-3 md:px-10 py-3 font-xs
                        lg:font-medium focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 ">
                        Register for Free Digital Profile
                    </Link>
                </div>
            </div>
            <div className="hidden lg:flex lg:basis-1/4 w-full h-full  " >
                {/* <Image className=" h-full rounded-l-3xl" src="/all-in-one.png" width={800} height={500} alt={''} /> */}
                <RegisterVideo />
            </div>
        </div >
        /*  <div className="relative h-[20rem] flex flex-row justify-center items-center  bg-[#535c9a] m-52 ">
             <div className="basis-1/4 w-full h-full rounded-3xl ">
                 <Image className=" h-full rounded-l-3xl" src="/all-in-one.png" width={800} height={500} alt={''} />
             </div>
             <div className="basis-3/4 flex flex-col justify-center items-center px-4  ">
                 <HeroHighlight>
                     <motion.h1
                         initial={{
                             opacity: 0,
                             y: 20,
                         }}
                         animate={{
                             opacity: 1,
                             y: [20, -5, 0],
                         }}
                         transition={{
                             duration: 0.5,
                             ease: [0.4, 0.0, 0.2, 1],
                         }}
                         className=" px-4   text-white max-w-4xl leading-relaxed lg:leading-snug text-center mx-auto "
                     >
                         <p className="text-[#F0F0F0] text-xl mb-5">All in one digital platform for</p>
                         {words.map((word, index) => (
                             <p key={index} className="inline-block font-normal lg:text-2xl" >
                                 {word}{index !== words.length - 1 ? ', ' : ' '}&nbsp;
                             </p>
                         ))}
                     </motion.h1>
                 </HeroHighlight>
             </div>
         </div > */
    );
}
