"use client";

import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

import Autoplay from "embla-carousel-autoplay";

import {
  CalendarIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { FeaturedEvent } from "./featuredEventsType";
import moment from "moment-timezone";
import Link from "next/link";

const FeaturedEventsSection = () => {
  const [featuredEvents, setFeaturedEvents] = React.useState<FeaturedEvent[]>(
    []
  );
  const getFeaturedProfiles = async () => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_HOST}/featured-events`,
        {
          method: "GET",
        }
      );
      if (res.ok) {
        const response = await res.json();
        const data = response.data["events"]["events"] ?? [];

        console.log("res ", data);

        setFeaturedEvents(data ?? []);
      } else {
        console.log("Oops! Something is wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  function formatDateTime(dateString: string): string {
    const date = moment.utc(dateString);
    date.tz("Asia/Kolkata");
    const formattedDate = date.format("ddd, MMM D - h:mm A z");

    return formattedDate.toUpperCase();
  }

  useEffect(() => {
    const _init = async () => {
      await getFeaturedProfiles();
    };
    _init();
  }, []);

  return (
    <>
      {featuredEvents.length > 0 && featuredEvents && (
        <>
          <h2 className="text-xl font-semibold mb-2 justify-center text-center">
            Upcoming Events
          </h2>
          <div className="flex justify-center w-full px-10 my-2">
            <Carousel
              opts={{
                align: "center",
                loop: true,
              }}
              plugins={[
                Autoplay({
                  delay: 3000,
                }),
              ]}
              className="w-full max-w-7xl"
            >
              <CarouselContent>
                {featuredEvents.map((event, index) => (
                  <CarouselItem
                    key={index}
                    className="md:basis-1/2 lg:basis-1/3 p-2"
                  >
                    <Link href={`/event/${event.id}`}>
                      <div className="flex flex-col md:flex-row items-center h-fit w-full p-8">
                        <div className="w-full bg-white border border-gray-200 rounded-md overflow-hidden">
                          <div className="relative h-48">
                            {event.featuredImage.url !== "" ? (
                              <img
                                className="w-full h-full object-cover"
                                src={event.featuredImage.url}
                                alt="Event"
                              />
                            ) : (
                              <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                                <CalendarIcon className="w-12 h-12 text-gray-500" />
                              </div>
                            )}
                            <div className="absolute top-2 left-2 bg-gray-900 text-white text-xs px-2 py-1 rounded-full">
                              <VideoCameraIcon className="inline w-4 h-4 mr-1" />
                              {event.eventMode === "online" && "Online Event"}
                              {event.eventMode === "hybrid" && "Hybrid Event"}
                              {event.eventMode === "offline" && "Offline Event"}
                            </div>
                          </div>
                          <div className="p-4">
                            <h3 className="text-lg font-bold truncate">
                              {event.title}
                            </h3>
                            <p className="text-sm text-gray-500 mt-1 truncate">
                              {event.conductedBy !== "" &&
                                `Conducted by: ${event.conductedBy}`}
                            </p>
                            <div className="mt-3 flex items-center text-sm text-gray-500">
                              <CalendarIcon className="w-5 h-5 mr-2" />
                              {formatDateTime(
                                event.slots[0].startTimestamp.toString()
                              )}
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="ml-auto text-primary font-medium">
                                {event.pricing === "free" && "Free"}
                                {event.pricing === "paid" && "Paid"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default FeaturedEventsSection;
