"use client"

import { HoverEffect } from "@/components/ui/card-hover-effect";

export function Section2() {
    return (
        <div className="mx-auto h-full md:max-w-screen-xl  lg:max-w-screen-xl  my-8">
            <div className="text-center">
                <span className="font-semibold text-xl text-center self-center md:w-1/4 align-middle text-indigo-900 bg-[#E6E6FA] px-2 py-1">Key Benefits</span>
            </div>
            <div className="">
                <HoverEffect items={projects} />

            </div>
        </div>
    );
}
export const projects = [
    {
        title: "Increased Visibility",
        description:
            "Reach a broader audience by being easily discoverable online, attracting more clients and opportunities.",
        link: "",
    },
    {
        title: "Enhanced Credibility",
        description:
            "Build trust and establish your professional reputation by showcasing your qualifications, certifications, and patient testimonials.",
        link: "",
    },
    {
        title: "Professional Networking",
        description:
            "Connect with other physiotherapists, healthcare professionals, and potential collaborators, fostering valuable professional relationships.",
        link: "",
    },
    {
        title: "Marketing and Branding",
        description:
            "Promote your services and brand yourself as an expert in your field through targeted online marketing strategies.",
        link: "",
    },
    {
        title: "Access to Reviews and Feedback",
        description:
            "Collect and display positive reviews and feedback from satisfied patients, which can enhance your reputation and attract new clients",
        link: "",
    },
    {
        title: "Business Growth",
        description:
            "Utilize digital tools and platforms to grow your practice, manage operations efficiently, and reach business goals more effectively.",
        link: "",
    },
];
