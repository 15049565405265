"use client";

import React from "react";

const ComingSoonSection = () => {
  return (
    <div className="mx-auto h-full md:max-w-screen-xl  lg:max-w-screen-xl msy-8 px-4">
      <h2 className="text-xl font-semibold mb-2 justify-center text-center">
        Stay Tuned for Exciting Features!
      </h2>
      <p className="text-gray-600 mb-4 text-center text-sm">Coming sooner than you expect</p>
      <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
        <div className="flex flex-col items-start">
          <div className="text-primary mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
              />
            </svg>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Forums</h3>
            <p className="text-gray-600">
              Join targeted discussions based on specializations and
              demographics, fostering a community where physiotherapists can
              exchange insights, best practices, and support each
              other&apos;s professional growth.
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="text-primary mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                stroke-linejoin="round"
                d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
              />
            </svg>
          </div>
          <h3 className="text-lg font-semibold mb-2">Events</h3>
          <p className="text-gray-600">
            Easily organize and promote events directly through the app. Share
            event links via WhatsApp and other platforms to attract
            registrations, nurturing potential leads and expanding your
            professional network.
          </p>
        </div>
        <div className="flex flex-col items-start">
          <div className="text-primary mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>
          </div>
          <h3 className="text-lg font-semibold mb-2">Marketplace</h3>
          <p className="text-gray-600">
            Access special pricing from trusted brands within the
            physiotherapy industry. Generate affiliate links for recommended
            products, enhancing patient care options while creating additional
            revenue streams.
          </p>
        </div>
        <div className="flex flex-col items-start">
          <div className="text-primary mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
              />
            </svg>
          </div>
          <h3 className="text-lg font-semibold mb-2">Analytics</h3>
          <p className="text-gray-600">
            Access special pricing from trusted brands within the
            physiotherapy industry. Generate affiliate links for recommended
            products, enhancing patient care options while creating additional
            revenue streams.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonSection;
