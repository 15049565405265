"use client";
import Image from "next/image"
import Link from "next/link"
import { Dialog, DialogPanel } from '@headlessui/react'
import { useState } from "react";
import { Bars3Icon, XMarkIcon, EnvelopeOpenIcon, HomeIcon, HomeModernIcon } from "@heroicons/react/24/solid";

// NAVIGATION
export const NAV_LINKS = [
  { href: '/', key: 'home', label: 'Home' },
  { href: '/register', key: 'regiser', label: 'Register' },
];

const NavbarV2 = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    /*     <header className="relative flex max-w-screen-xl flex-col overflow-hidden px-4 py-4 text-blue-900 md:mx-auto md:flex-row md:items-center">
     */
    <div className="fixed inset-x-0 top-0 lg:px-20 z-50  bg-[#272947]">
      <nav className="flex flex-row items-center justify-between p-4 md:p-6 md:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Gritup</span>
            <img
              className="h-6 md:h-8 w-auto"
              src="/360-logo-white.png"
              alt="Gritup Logo"
            />
          </Link>
        </div>

        {/*         <nav aria-label="Header Navigation" className="peer-checked:mt-8 peer-checked:max-h-56 flex max-h-0 w-full flex-col items-center justify-between overflow-hidden transition-all md:ml-24 md:max-h-full md:flex-row md:items-start">
 */}
        <div className="hidden lg:flex lg:gap-x-10">
          {NAV_LINKS.map((link) => (
            <Link href={link.href} key={link.key} className="text-base font-semibold leading-6 text-gray-100">
              {link.label}
            </Link>
          ))}
        </div>

        <div className="flex flex-1 justify-end mr-1 md:mr-5">
          <div className="hidden md:flex md:mr-5 text-xs md:bg-gray-200 p-2 rounded-md font-semibold flex-row md:w-40">
            <div className="hidden md:flex basis-1/4">
              <EnvelopeOpenIcon className="w-4 h-4 text-gray-600 font-light" />
            </div>
            <div className="flex basis-3/4 text-gray-600 font-light">
              support@gritup.io
            </div>
          </div>
          <div className="flex lg:hidden">
            <Link href="/">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-3 px-5 text-blue-100">
                <HomeIcon className="h-6 w-6 font-bold" aria-hidden="true" />
              </button>
            </Link>
          </div>
          <Link href="/login">
            <button className="rounded-lg border border-gray-900 font-semibold px-6 py-1 text-black transition-colors bg-blue-400 hover:bg-blue-500">Login</button>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-100"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-8 w-8 font-bold" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-40" />
        <DialogPanel className="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="#" className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src="/logo.png"
                alt=""
              />
            </Link>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {NAV_LINKS.map((item) => (
                  <Link
                    key={item.key}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.label}
                  </Link>
                ))}
              </div>
              <div className="py-6">

                <Link
                  href="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </Link>

                <div className="flex text-xs md:bg-gray-200 rounded-md font-semibold flex-row">
                  <div className="flex basis-3/4 text-gray-600 font-light">
                    Write us at: support@gritup.io
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
    /*     <nav className="flexBetween max-container padding-container relative z-30 py-5">
          <Link href="/">
            <Image src="/logo.png" alt="logo" width={200} height={100} />
          </Link>
    
          <ul className="hidden h-full gap-12 lg:flex">
            {NAV_LINKS.map((link) => (
              <Link href={link.href} key={link.key} className="regular-16  flexCenter cursor-pointer pb-1.5 transition-all hover:font-bold">
                {link.label}
              </Link>
            ))}
          </ul>
    
          <div className="lg:flexCenter hidden">
            <Button 
              type="button"
              title="Login"
              icon="/user.svg"
              variant="btn_dark_green"
            />
          </div>
    
          <Image 
            src="menu.svg"
            alt="menu"
            width={32}
            height={32}
            className="inline-block cursor-pointer lg:hidden"
          />
        </nav> */
  )
  /*   return (
  
      <div className="fixed inset-x-0 top-0 lg:px-20 z-50 bg-[#272947]">
        <Image
          className="absolute -bottom-32 -right-64 hidden lg:flex"
          src="/blur-cyan.png"
          alt=""
          width={500}
          height={500}
          unoptimized
          priority
        />
        <nav className="flex items-center justify-between p-4 md:p-6 md:px-8 " aria-label="Global">
          <div className="flex lg:flex-1">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Gritup</span>
              <img
                className="h-6 md:h-8 w-auto"
                src="/360-logo-white.png"
                alt="Gritup Logo"
              />
            </Link>
          </div>
  
          
          <div className="hidden lg:flex lg:gap-x-10">
            {NAV_LINKS.map((link) => (
              <Link href={link.href} key={link.key} className="text-xl leading-6 text-blue-100">
                {link.label}
              </Link>
            ))}
          </div>
  
          <div className="flex flex-1 justify-end mr-1 md:mr-5">
            <div className="flex lg:hidden">
              <Link href="/">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-3 px-5 text-blue-100">
                  <HomeIcon className="h-6 w-6 font-bold" aria-hidden="true" />
                </button>
              </Link>
            </div>
            <Link href="/login">
              <button className="rounded-lg border border-gray-900 font-semibold px-6 py-1 text-black transition-colors bg-blue-400 hover:bg-blue-500">Login</button>
            </Link>
            <div className="hidden md:flex md:ml-5 text-xs md:bg-gray-200 p-2 rounded-md font-semibold flex-row md:w-40">
              <div className="hidden md:flex basis-1/4">
                <EnvelopeOpenIcon className="w-4 h-4 text-gray-600 font-light" />
              </div>
              <div className="flex basis-3/4 text-gray-600 font-light">
                support@gritup.io
              </div>
            </div>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white ${mobileMenuOpen ? 'hidden' : 'block'}`}
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-8 w-8 font-bold" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white ${mobileMenuOpen ? 'block' : 'hidden'}`}
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close main menu</span>
              <XMarkIcon className="h-8 w-8 font-bold" aria-hidden="true" />
            </button>
          </div>
        </nav>
        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-40" />
          <DialogPanel className="fixed inset-y-0 right-0 z-40 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link href="#" className="-m-1.5 p-1.5">
                <img
                  className="h-8 w-auto"
                  src="/logo.png"
                  alt=""
                />
              </Link>
         
               <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-100"
                onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>  
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {NAV_LINKS.map((item) => (
                    <Link
                      key={item.key}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-50"
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
                <div className="py-6">
  
                  <Link
                    href="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-100 hover:bg-gray-50"
                  >
                    Log in
                  </Link>
  
                  <div className="flex text-xs md:bg-gray-200 rounded-md font-semibold flex-row">
                    <div className="flex basis-3/4 text-gray-600 font-light">
                      Write us at: support@gritup.io
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </div>
  
    ) */
}

export default NavbarV2