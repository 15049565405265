"use client";

import React, { useEffect } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

import Autoplay from "embla-carousel-autoplay";

import { RectangleGroupIcon } from "@heroicons/react/24/outline";

import Link from "next/link";
import { GroupDetailsType } from "./featuredGroupsType";
import moment from "moment";

const FeaturedGroupsSection = () => {
  const [featuredGroups, setFeaturedGroups] = React.useState<
    GroupDetailsType[]
  >([]);
  const getFeaturedGroups = async () => {
    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_HOST}/groups`, {
        method: "GET",
      });
      if (res.ok) {
        const response = await res.json();

        const data = response["data"] ?? [];

        setFeaturedGroups(data ?? []);
      } else {
        console.log("Oops! Something is wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const _init = async () => {
      await getFeaturedGroups();
    };
    _init();
  }, []);

  return (
    <>
      {featuredGroups.length > 0 && featuredGroups && (
        <section className="m-6">
          <div className="flex flex-col md:flex-row my-16">
            <div className="flex flex-row md:flex-col mx-6 justify-center items-center">
              <div className="flex flex-col justify-center items-center w-full my-4 text-center md:text-start">
                <div className="text-3xl md:text-5xl text-gray-800 font-semibold">
                  Explore <span className='text-primary'>Groups</span> and <span className='text-secondary'>Communities</span>
                </div>
                <div className="text-tertiary mt-1 md:mt-4 font-semibold text-md md:text-lg">
                  Join Dynamic Communities That Inspire Collaboration and Growth
                </div>
              </div>
            </div>
            <div className="flex flex-row md:flex-col px-10 w-full justify-center items-center">
              <Carousel
                opts={{
                  align: "center",
                  watchResize: true,
                  loop: true,
                }}
                plugins={[
                  Autoplay({
                    delay: 3000,
                  }),
                ]}
              >
                <CarouselContent>
                  {featuredGroups.map((group, index) => (
                    <CarouselItem
                      key={index}
                      className="lg:basis-1/2 p-2"
                    >
                      <Link href={`/group/${group._id}/home`} >
                        <div className="flex flex-row justify-center items-center">
                          <div className=" bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg">
                            <div className=" h-4/5 items-center align-middle">
                              {group.logo.url !== "" ? (
                                <img
                                  className="w-60 h-60 object-cover"
                                  src={group.logo.url}
                                  alt="Event"
                                />
                              ) : (
                                <div className="w-full h-full bg-gray-300 flex items-center justify-center">
                                  <RectangleGroupIcon className="w-12 h-12 text-gray-500" />
                                </div>
                              )}
                            </div>
                            <div className="h-1/5 align-bottom p-2 items-center text-center">
                              <h3 className="text-sm md:text-base font-bold truncate whitespace-nowrap overflow-hidden">
                                {group.name}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FeaturedGroupsSection;
