"use client";
import React from 'react'
import ReactPlayer from 'react-player/youtube'

export default function RegisterVideo() {
    return (<>
        <ReactPlayer
            url={"https://youtu.be/Vm6b1Q82eos"}
            loop={true}
            playing={true}
            controls={false}
            volume={0}
            muted={true}
        />
    </>)
}