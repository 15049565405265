import React, { useState, useEffect } from 'react';

interface ProfileImageProps {
    profilePictureURL: string | undefined;
    firstName: string;
    lastName: string;
    height: string | '7rem',
    width: string | '7rem'
}

const useImageStatus = (imageUrl: string | undefined) => {
    const [status, setStatus] = useState('loading');
    useEffect(() => {
        if (!imageUrl) {
            setStatus('missing');
            return;
        }

        const img = new Image();
        img.onload = () => setStatus('loaded');
        img.onerror = () => setStatus('error');
        img.src = imageUrl;
    }, [imageUrl]);

    return status;
};

const UserProfilePic: React.FC<ProfileImageProps> = ({ profilePictureURL, firstName, lastName, height = '7rem', width = '7rem' }) => {
    const imageStatus = useImageStatus(profilePictureURL);
    if (imageStatus === 'loading') {
        return <p>Loading image...</p>;
    }
    if (imageStatus === 'error' || imageStatus === 'missing') {
        const createInitials = (firstName: string, lastName: string): string => {
            if (!firstName || !lastName) return "";

            const initials = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
            return initials;
        };

        const initials = createInitials(firstName, lastName);
        return (
            <div className="flex flex-col items-center shadow-sm justify-center bg-tertiary/20 rounded-lg mb-4 md:mb-0 md:mr-2" style={{ width, height }}>
                <span className="text-xl font-semibold">{initials}</span>
            </div>
        );
    }

    return (
        <img
            className="flex flex-col object-contain rounded-lg mb-4 md:mb-0 md:mr-2"
            style={{ width, height }}
            src={profilePictureURL}
            alt="Profile"
        />
    );
};

export default UserProfilePic;
