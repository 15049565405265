import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/constants/fonts.defaults.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/constants/fonts.defaults.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/ComingSoonSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/FeaturedEventsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/FeaturedGroups.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/FeaturedNewsLetterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/home/ProfilesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section1"] */ "/app/src/app/(root)/home/Section1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section2"] */ "/app/src/app/(root)/home/Section2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(root)/NavbarV2.tsx");
