"use client";

import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

import Autoplay from "embla-carousel-autoplay";

import { NewspaperIcon } from "@heroicons/react/24/outline";

import Link from "next/link";

import { NewsLetterType } from "./featuredNewsLetterType";
import { Button } from "@/components/ui/button";
import { Document, Page, pdfjs } from "react-pdf";

// // In a component that needs to use this
// import 'pdfjs-dist/build/pdf.worker.min.mjs';

// // And delete this or some example implementation shown in the doc:
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = '/scripts/pdf.worker.min.js';

const FeaturedNewsLetterSection = () => {
  const [featuredNews, setFeaturedNews] = React.useState<NewsLetterType[]>([]);
  const getFeaturedNewsLetters = async () => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_HOST}/newsletters/featured`,
        {
          method: "GET",
        }
      );
      if (res.ok) {
        const response = await res.json();

        const data = response["data"] ?? [];

        setFeaturedNews(data ?? []);
      } else {
        console.log("Oops! Something is wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const _init = async () => {
      await getFeaturedNewsLetters();
    };
    _init();
  }, []);
  const [selectedNewsletter, setSelectedNewsletter] =
    useState<NewsLetterType | null>(null);
  return (
    <>
      {featuredNews.length > 0 && featuredNews && (
        <section className=" bg-gray-50 m-6">
          <div className="flex flex-col md:flex-row border p-5 gap-2 rounded-3xl bg-gradient-to-r from-primary/90 via-seconday/90 to-tertiary/90 text-gray-100">
            <div className="flex flex-row md:flex-col mx-6 justify-center items-center">
              <div className="flex flex-col justify-center items-center w-full my-4">
                <span className="text-3xl font-semibold mb-2 justify-center text-center ">
                  Featured Newsletters, Articles
                </span>
                <span className="font-normal mb-2 justify-center text-center">
                  Explore selection of the most insightful newsletters, blogs, and articles
                </span>
              </div>
            </div>
            <div className="flex flex-row md:flex-col w-full px-10 justify-center items-center">
              <Carousel
                opts={{
                  align: "center",
                  loop: true,
                }}
                plugins={[
                  Autoplay({
                    delay: 3000,
                  }),
                ]}
              >
                <CarouselContent
                  className="gap-2"
                >
                  {featuredNews.map((latestNewsletter, index) => (
                    <CarouselItem
                      key={index}
                      className="lg:basis-1/2 p-2"
                    >
                      {latestNewsletter ? (
                        <Link href={`/group/${latestNewsletter.groupId}/newsletters`}>
                          <div className="flex flex-row justify-center items-center">
                            <div className="py-2 ring-1 hover:ring-4 shadow-md rounded-lg flex flex-col items-center text-center w-fit h-full text-gray-100">
                              <div className="h-4/5 items-center align-middle ">
                                <Document file={latestNewsletter.file.url}
                                  className={"cursor-pointer"}
                                  onClick={() => {
                                    setSelectedNewsletter(latestNewsletter)
                                  }}>
                                  <Page
                                    pageNumber={1}
                                    width={150}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                  />
                                </Document>
                              </div>
                              <div className="h-1/5 align-bottom pt-1">
                                <h3 className="text-base font-mono font-bold m-2">{latestNewsletter.title}</h3>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                        <div>No latest newsletter available.</div>
                      )}
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default FeaturedNewsLetterSection;
