"use client";

import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Card, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { FeaturedProfiles } from "./types";
import Autoplay from "embla-carousel-autoplay";
import UserProfilePic from "./UserProfilePic";

const ProfilesSection = () => {
  const [featuredProfiles, setFeaturedProfiles] = React.useState<
    FeaturedProfiles[] | null
  >(null);
  const getFeaturedProfiles = async () => {
    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_HOST}/user-profiles/recent`,
        {
          method: "GET",
        }
      );
      if (res.ok) {
        const response = await res.json();
        const data = response.data;

        setFeaturedProfiles(data);
      } else {
        console.log("Oops! Something is wrong.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const _init = async () => {
      await getFeaturedProfiles();
    };
    _init();
  }, []);

  const [showImage, setShowImage] = useState(true); // State can now be used properly

  return (
    <section className=" bg-gray-50">
      <div className="container flex flex-col py-10 ">
        <h2 className="text-xl font-semibold mb-2 justify-center text-center">
          Be a Part of Our Thriving Physiotherapist Platform
        </h2>
        {featuredProfiles && (
          <div className="flex flex-row justify-center w-full overflow-hidden">
            <Carousel
              opts={{
                align: "center",
                loop: true,
              }}
              plugins={[
                Autoplay({
                  delay: 3000,
                }),
              ]}
              className="w-full max-w-7xl"
            >
              <CarouselContent>
                {featuredProfiles.map((user, index) => (
                  <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 p-1 px-5">
                    <div
                      key={user.id}
                      className="flex flex-col md:flex-row bg-white p-3 rounded-2xl shadow-md items-center md:items-start h-fit"
                    >
                      <UserProfilePic
                        profilePictureURL={user.profilePictureURL ?? ""}
                        firstName={user.firstName}
                        lastName={user.lastName} height={"7rem"} width={"7rem"}                    // Add any other required props here
                      />

                      {/*  {showImage ? (
                      <img
                        className="w-32 h-32 object-cover rounded-lg mb-4 md:mb-0 md:mr-2"
                        src={user.profilePictureURL}
                        alt="Profile"
                        onError={() => setShowImage(true)}
                      />
                    ): (
                      <div className="w-32 h-32 flex items-center justify-center bg-gray-200 rounded-lg mb-4 md:mb-0 md:mr-2">
                        <span className="text-xl font-semibold">T</span>
                      </div>
                    )} */}

                      <div className="flex flex-col items-start">
                        <h3 className="text-sm md:text-md font-semibold">
                          {(user.title ? `${user.title}. ` : "") +
                            user.firstName +
                            (user.lastName ? ` ${user.lastName}` : "") +
                            (user.suffix ? ` ${user.suffix}` : "")}
                        </h3>
                        <p className="text-primary font-bold mb-1">
                          {user.profession}
                        </p>
                        <Separator className="border-gray-200 border-t-2 my-1" />
                        <p className="text-gray-600 text-sm line-clamp-3 text-start">
                          {user.about}
                        </p>
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfilesSection;
